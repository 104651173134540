import React, { useRef, useState, useEffect } from "react";
import Style from "./selectWithIcons.module.scss";
import { SlGraph } from "react-icons/sl";

export const SelectWithIcons = ({
  data,
  handleViewTooltip,
  handleHideTooltip,
  showTooltip,
}) => {
  const graphRef = useRef(null);
  let count = 0;

  const handleToggleTooltip = (e, name) => {
    
    if (name === "divClick" && count !== 0) {
      count = 0; // Reset the count when clicking inside
    }

    if (!showTooltip && count === 0 && name === "divClick") {
      handleViewTooltip(e, data); // Show tooltip if it's hidden and count is 0
    }
  };

  useEffect(() => {
    if (showTooltip) {
          const handleClickOutside = (event) => {
            if (graphRef.current && !graphRef.current.contains(event.target)) {
              handleHideTooltip();
              count = 1
            }
          };
    
          document.addEventListener("mousedown", handleClickOutside);
    
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }
  }, [showTooltip, graphRef]);

  return (
    <div
      className={`pro-d-flex ${Style.avatar_container}  ${Style.LineGraphTooltip} avatar-container`}
    >
      <div
        className={`pro-overflow-hidden pro-align-self-center ${Style.graph_tooltip_wrap}`}
        onClick={(e) => handleToggleTooltip(e, "divClick")}
        ref={graphRef} // Attach ref to the clickable div
      >
        <span className="pro-d-flex pro-items-center">
          <i className="icon">
            <SlGraph />
          </i>
          {data?.drop_off_time}
        </span>
      </div>
    </div>
  );
};
