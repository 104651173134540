import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] === "status") {
        return `${key}=${parseInt(params[key])}&`;
      } else if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    try {
      const response = await api[method](
        `${endpoint}?${getParams("_id")}${getParams("from_date")}${getParams(
          "to_date"
        )}${getParams("status")}${getParams("sort_by")}sort_order=${
          params?.sort_order || ""
        }&page_size=${params?.page_size || "10"}&${getParams("search")}&page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      };
    }
  };

export const homeDashboard = createApi({
  reducerPath: "homeDashboardApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["HomeDashboard"],
  endpoints: (builder) => ({
    getHomeDashboardListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/dashboard/analytics`,
      }),
      provideTags: ["HomeDashboard"],
    }),
    getFireBaseDashboardListData: builder.query({
      query: (body) => ({
        method: "get",
        params: body,
        endpoint: `admin/firebase/analytics`,
      }),
      provideTags: ["HomeDashboard"],
    }),
  }),
});

export const {
  useGetHomeDashboardListDataQuery,
  useGetFireBaseDashboardListDataQuery,
} = homeDashboard;
