import DashboardHero from "../DashboardHero";
import useHomeDashboardListing from "./useHomeDashboardListing";
import Style from "./dashboardListing.module.scss";
import EmptyData from "../../Global/EmptyData";
import {
  Button,
  DataContainer,
  NavGroup,
} from "@wac-ui-dashboard/wac_component_library";
import Charts from "../../Global/Charts";
import { Link } from "react-router-dom";
import BarChart from "../BarChart";
import TopPerformance from "../TopPerformance";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import TopRjList from "../TopRjs";
import TopProgramList from "../TopPrograms";
import TopPlayedList from "../TopPlayed";
import { Doughnut } from "react-chartjs-2";
import TopGenresList from "../TopGenres";
import DashboardCardShimmer from "../../Global/DashboardCardShimmer";
import { Tooltip } from "react-tooltip";
const HomeDashboardListing = () => {
  const {
    dashboardItems,
    dashboardData,
    isFetching,
    children,
    listingType,
    doughnutData,
    genderGraph,
    doughnutChartData,
    doughnutChartOptions,
    doughnutTextCenter,
    semiDoughnutTextCenter,
    barData,
    barDataOptions,
    tabNavs,
    filteredTotals,
    ageBarData,
    ageBarDataOptions,
    lineData,
    lineOptions,
    deviceDoughnutData,
    deviceDoughnutOption,
    handleDateRangeChange,
    date,
    activeTab,
    ExportData,
    barDataCities,
    barDataCitiesOptions,
    fireBasedData,
    isFireBasedDataFetching,
  } = useHomeDashboardListing();


  const mediaTypes = ["videos", "podcasts", "shorts"];
  const baseTypes = [
    "average_engagement_time",
    "deleted_users",
    "download_count",
  ];

  return (
    <>
      <div className="row pro-justify-end ">
        <div className="col">
          <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
        </div>
        {activeTab !== "app_analytics" && (
          <div className="col-auto">
            <Button
              className="pro-btn-primary pro-lh-base"
              onClick={() => ExportData()}
            >
              Export
            </Button>
          </div>
        )}

        <div className="col-auto">
          <CustomDateRangePicker
            defaultDateRange={date}
            handleDateRangeChange={handleDateRangeChange}
          />
        </div>
      </div>

      <div className={`pro-w-100 pro-my-5`}>
        <div className={`row g-4 row-cols-4 card_listing`}>
          <DashboardHero
            data={filteredTotals}
            obj={dashboardItems}
            loading={isFetching}
            listingType={Object.keys(dashboardData?.data?.totals || {}) ?? ""}
            shimmerCount={3}
          />
          {!isFetching ? (
            <div className={`col card_col`}>
              <DataContainer>
                {dashboardData?.data?.totals && (
                  <>
                    <div
                      className={`${Style.media_count_status} pro-d-flex pro-items-center  pro-gap-4`}
                    >
                      {mediaTypes.map((type) => (
                        <div
                          key={type}
                          className="col pro-d-flex pro-flex-column pro-justify-between "
                          data-tooltip-id={`tooltip-${type}`}
                          data-tooltip-html={
                            dashboardData?.data?.totals?.[type]?.label
                              ? `<h6>${
                                  dashboardData?.data?.totals?.[type]?.label
                                } :</h6><p>${
                                  dashboardData?.data?.totals?.[type]
                                    ?.tooltip || ""
                                }</p>`
                              : ``
                          }
                        >
                          <div className={Style.top_wrap}>
                            <h3
                              className={`${Style.count} pro-ttl h3 pro-mb-2 pro-fw-medium`}
                            >
                              {dashboardData?.data?.totals?.[type]?.current}
                            </h3>
                            <h6
                              className={`${Style.category} pro-ttl h6 pro-mb-0 pro-fw-medium`}
                            >
                              {dashboardData?.data?.totals?.[type]?.label}
                            </h6>
                          </div>
                          <Tooltip
                            id={`tooltip-${type}`}
                            place="top"
                            effect="solid"
                            variant="white"
                          />

                          <div
                            className={`${Style.bottom_wrap} pro-pt-4 pro-mt-auto`}
                          >
                            <span className={Style.total}>
                              Total -{" "}
                              <strong>
                                {dashboardData?.data?.totals?.[type]?.total}
                              </strong>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </DataContainer>
            </div>
          ) : (
            <div className={`col card_col`}>
              <DashboardCardShimmer />
            </div>
          )}
          {!isFireBasedDataFetching ? (
            <div className={`col-6 card_col`}>
              <DataContainer>
                {fireBasedData?.data && (
                  <>
                    <div
                      className={`${Style.media_count_status} pro-d-flex pro-items-center  pro-gap-4`}
                    >
                      {baseTypes.map((type) => (
                        <div
                          key={type}
                          className="col pro-d-flex pro-flex-column pro-justify-between "
                          data-tooltip-id={`tooltip-${type}`}
                          data-tooltip-html={
                            fireBasedData?.data?.[type]?.label
                              ? `<span>${
                                  fireBasedData?.data?.[type]?.label
                                } :</span><p>${
                                  fireBasedData?.data?.[type]?.tooltip || ""
                                }</p>`
                              : ``
                          }
                        >
                          <div className={Style.top_wrap}>
                            <h3
                              className={`${Style.count} pro-ttl h3 pro-mb-2 pro-fw-medium`}
                            >
                              {fireBasedData?.data?.[type]?.value}
                            </h3>
                            <span
                              className={Style.total}
                            >
                              {fireBasedData?.data?.[type]?.label}
                            </span>
                          </div>
                          <Tooltip
                            id={`tooltip-${type}`}
                            place="top"
                            effect="solid"
                            variant="white"
                          />
                          

                        </div>
                        
                      ))}
                      {/* {fireBasedData?.data?.["active_users"] && (
                            <div
                              className={`${Style.bottom_wrap} pro-pt-4 pro-mt-auto`}
                            >
                              {fireBasedData?.data?.["active_users"]?.map((type) => (
                              <span className={Style.total}>
                                {type?.label} -{" "}
                                <strong>
                                  {type?.value}
                                </strong>
                              </span>
                              ))}
                            </div>
                          )} */}
                    </div>
                  </>
                )}
              </DataContainer>
            </div>
          ) : (
            <div className={`col-6 card_col`}>
              <DashboardCardShimmer />
            </div>
          )}
          {!isFireBasedDataFetching ? (
            <div className={`col-6 card_col`}>
              <DataContainer>
                {fireBasedData?.data?.["active_users"] && (
                  <>
                  <h5>Active Users</h5>
                  <div
                    className={`${Style.media_count_status} pro-d-flex pro-items-center pro-gap-4`}
                  >
                    {fireBasedData?.data?.["active_users"]?.map((item, index) => (
                      <div
                        key={index}
                        className="col pro-d-flex pro-flex-column pro-justify-between"
                        data-tooltip-id={`tooltip-${index}`}
                        data-tooltip-html={
                          item.label
                            ? `<span>${item.label} :</span><p>${item.tooltip || ""}</p>`
                            : ``
                        }
                      >
                        <div className={Style.top_wrap}>
                          <h3
                            className={`${Style.count} pro-ttl h3 pro-mb-2 pro-fw-medium`}
                          >
                            {item.value}
                          </h3>
                          <span
                            className={Style.total}
                          >
                            {item.label}
                          </span>
                        </div>
                        <Tooltip
                          id={`tooltip-${index}`}
                          place="top"
                          effect="solid"
                          variant="white"
                        />
                      </div>
                    ))}
                  </div>
                </>
                
                )}
              </DataContainer>
            </div>
          ) : (
            <div className={`col-6 card_col`}>
              <DashboardCardShimmer />
            </div>
          )}
        </div>
      </div>
      {/* {dashboardData?.data && Object.keys(dashboardData?.data)?.length !== 0 ? (
        children
      ) : (
        <> 
          <EmptyData />
        </>
      )} */}
      {activeTab === "app_analytics" && (
        <div className={Style.charts_container}>
          {!isFetching ? (
            <>
              <div className="row gx-5 pro-mb-5">
                <div className={`${Style.graph_box} ${Style.box_lg} flex-fill`}>
                  <DataContainer>
                    <div className="pro-d-flex pro-h-100 pro-gap-6">
                      <div className={`${Style.line_chart_info} `}>
                        <h6 className="pro-ttl h6 mb-4">Streams</h6>
                        <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
                          {
                            dashboardData?.data?.streamsLineChart?.totals
                              ?.current
                          }
                        </h3>
                        <span
                          className={`${Style.growth_percentage} ${Style.increment} pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
                        >
                          <span className="material-symbols-outlined dashboardCard_arrow__t-XtB">
                            arrow_outward
                          </span>
                          <span>
                            {
                              dashboardData?.data?.streamsLineChart?.totals
                                ?.growth
                            }
                          </span>
                        </span>
                      </div>
                      <div className={`${Style.line_chart_wrap} pro-d-flex`}>
                        <Charts
                          type="line"
                          labels={lineData?.labels}
                          datasets={lineData?.datasets}
                          options={lineOptions}
                        />
                      </div>
                    </div>
                  </DataContainer>
                </div>
                <div className={`${Style.graph_box}`}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Age Category</h6>
                    <div className={Style.bar_chart_wrap}>
                      <Charts
                        type="bar"
                        labels={ageBarData?.labels}
                        datasets={ageBarData?.datasets}
                        options={ageBarDataOptions}
                      />
                    </div>
                  </DataContainer>
                </div>
                {/* <div className={`${Style.graph_box}`}>
                <DataContainer>
                  <div className="pro-w-100">
                    <TopPerformance dashboardData={dashboardData} />
                  </div>
                </DataContainer>
              </div> */}
              </div>
              <div className="row gx-5 pro-mb-5">
                <div className={`${Style.graph_box} flex-fill`}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Top Countries</h6>
                    <div className={Style.bar_chart_wrap}>
                      <Charts
                        type="bar"
                        labels={barData?.labels}
                        datasets={barData?.datasets}
                        options={barDataOptions}
                      />
                    </div>
                  </DataContainer>
                </div>

                <div className={`${Style.graph_box} ${Style.box_lg} `}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Gender</h6>
                    {/* <Charts
                  type="doughnut"
                  labels={doughnutData?.labels}
                  datasets={doughnutData?.datasets}
                  options={genderGraph}
                /> */}

                    {/* doughnutChartData,
                doughnutChartOptions,
                doughnutTextCenter, */}
                    {dashboardData?.data?.genderPieChart?.total_count ? (
                      <div className={Style.doughnut_chart_wrap}>
                        <Doughnut
                          data={doughnutData}
                          options={genderGraph}
                          plugins={[doughnutTextCenter]}
                        />
                      </div>
                    ) : (
                      <>
                        <EmptyData />
                      </>
                    )}
                  </DataContainer>
                </div>
              </div>
              <div className="row gx-5 pro-mb-5">
                <div className={`${Style.graph_box} flex-fill`}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Top Regions</h6>
                    <div className={Style.bar_chart_wrap}>
                      <Charts
                        type="bar"
                        labels={barDataCities?.labels}
                        datasets={barDataCities?.datasets}
                        options={barDataCitiesOptions}
                      />
                    </div>
                  </DataContainer>
                </div>
              </div>
              <div className="row gx-5 pro-mb-5">
                <div className={`${Style.graph_box}  flex-fill`}>
                  <DataContainer>
                    <BarChart dashboardData={dashboardData} />
                  </DataContainer>
                </div>

                <div className={`${Style.graph_box} ${Style.box_lg}`}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Devices</h6>
                    {/* <Charts
                  type="doughnut"
                  labels={deviceDoughnutData?.labels}
                  datasets={deviceDoughnutData?.datasets}
                  options={deviceDoughnutOption}
                /> */}
                    {dashboardData?.data?.devicesPieChart?.total_count ? (
                      <div className={Style.doughnut_chart_wrap}>
                        <Doughnut
                          data={deviceDoughnutData}
                          options={deviceDoughnutOption}
                          plugins={[semiDoughnutTextCenter]}
                        />
                      </div>
                    ) : (
                      <>
                        <EmptyData />
                      </>
                    )}
                  </DataContainer>
                </div>
              </div>
            </>
          ) : (
            <div className="app-loading fill"></div>
          )}
        </div>
      )}

      {activeTab === "content_analytics" && (
        <div className={`row row-cols gx-5 pro-mb-5 ${Style.tab_table_wrap}`}>
          {/* <TopRjList dashboardData={dashboardData?.data?.topCategories?.rjs} /> */}
          <div className="col-12 pro-mb-4">
            <TopProgramList
              dashboardData={dashboardData?.data?.programListData}
            />
          </div>
          <div className="col-12 pro-mb-4">
            <TopGenresList
              dashboardData={dashboardData?.data?.categoryListData}
            />
          </div>
        </div>
      )}
      {/* <div className="row row-cols-3 gx-5 pro-mb-5">
        <TopRjList dashboardData={dashboardData?.data?.topCategories?.rjs} />
        <TopProgramList
          dashboardData={dashboardData?.data?.topCategories?.programs}
        />
        <TopPlayedList
          dashboardData={dashboardData?.data?.topCategories?.played}
        />
      </div> */}
    </>
  );
};

export default HomeDashboardListing;
