import { useParams } from "react-router-dom";
import { exportAllData, updateConfig } from "../../../../store/slices/TopPrograms/topProgramsSlice";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getFormatedDate, getFormatedDateRange } from "../../../../utils/functions/table";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch } from "react-redux";

const useProgramDetails = ({ programDetailData}) => {

  const topProgramState = useSelector((state) => state.topPrograms);
  const dashboardState = useSelector((state) => state.homeDashboard);
  const dispatch = useDispatch();


  const headerLabel = {
    new_followers: { label: "New Followers", tooltip:  "New followers a program gained over a given date range."}, 
    total_followers: { label: "Total Followers", tooltip:  "Total followers a program gained over a given date range."}, 
    plays: { label: "Plays", tooltip:  "The number of times this program has been watched or listened to."}, 
    retention_rate: { label: "Retention Rate", tooltip:  "The percentage of the Users who return to the content after their initial visit."}, 
    total_paly_time: { label: "Total Play Time", tooltip:  "Total time spent watching or listening to a program."}, 
    average_age: { label: "Average Age", tooltip:  "Average age of total listers/viewers of the program."}, 
    average_watch_time: { label: "Average Watch Time", tooltip:  "Average time spent watching or listening to a program."}, 
    share: { label: "Share"}, 
    // new_followers: "New Followers",
    // total_followers: "Total Followers",
    // plays: "Plays",
    // retention_rate: "Retention Rate",
    // total_paly_time: "Total Play Time",
    // average_age: "Average Age",
    // average_watch_time: "Average Watch Time",
    // share: "Share"
  };

  const overView = programDetailData?.data || {};

  const HeaderValue = {
    new_followers: `${overView.sectionDetails?.new_followers || "0"}`,
    total_followers: `${overView.sectionDetails?.total_followers || "0"}`,
    plays: `${overView.sectionDetails?.plays || "0"}`,
    retention_rate: `${overView.sectionDetails?.retention_rate || "0"}`,
    total_paly_time: `${overView.sectionDetails?.total_play_time || "0"}`,
    average_age: `${overView.sectionDetails?.average_age || "0"}`,
    average_watch_time: `${overView.sectionDetails?.average_watch_time || "0"}`,
    // share: `${overView.sectionDetails?.shares || "0"}`
  };

  const headerDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });

  const barData = {
    labels: overView?.mostActiveCountries?.labels,
    datasets:[
      {
        label: "",
        data: overView?.mostActiveCountries?.data,
        backgroundColor: [
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)'
        ],
        borderWidth: 0,
      barThickness: 22,
      }
    ]
      
  };

  const barDataOptions = {
    indexAxis: "x",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const ageBarData = {
    labels: overView?.ageAndGenderDistribution?.labels,
    datasets: overView?.ageAndGenderDistribution?.datasets,
    backgroundColor: [
      "rgba(245, 158, 11, 1)",
      "rgba(217, 119, 6, 1)",
      "rgba(253, 186, 116, 1)",
    ],
     
  };

  const ageBarDataOptions = {
    indexAxis: "x",
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          borderColor: "transparent",
          itemMargin: 5,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1
      },
      layout: {
        padding: {
          left: 0,
          right: 10,
          top: 0,
          bottom: 50,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const ExportData = () => {
    try {
      let obj = {
        sort_by: topProgramState.episodeSortBy,
    sort_order: topProgramState.episodeSortOrder,
    from_date: getFormatedDate(topProgramState?.startDate),
    to_date: getFormatedDate(topProgramState?.endDate),
    dashboard_type : dashboardState?.activeTab === "content_analytics" ? 2 : 1,
    program_id: topProgramState?.program_id,
    media_type: topProgramState?.media_type,
        
      };

      exportAllData(obj).then((response) => {
  
        if (response?.status === 200) {
          
          toast.success("Success");

          let downloadLink = document.createElement("a");
          let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute(
            "download",
            `Program${overView.sectionDetails?.name}_${getFormatedDateRange(date?.startDate)} - ${getFormatedDateRange(date?.endDate)}.xlsx`
          );

          document.body.appendChild(downloadLink);
          downloadLink.click();
          
        } else if (response?.data?.error_code === 403) {
          toast.error(response?.data?.errors);
        } else if (response?.status_code === 500) {
          toast.error("Something Went Wrong");
        } 
      });
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  const [date, setDate] = useState({
    startDate: dashboardState.startDate,
    endDate: dashboardState.endDate,
    key: "selection",
  });

  const handleDateRangeChange = (range) => {
    
    const startDate = range[0];
    const endDate = range[1];


    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    headerDetails,
    barData,
    barDataOptions,
    ageBarData,
    ageBarDataOptions,
    ExportData,
    handleDateRangeChange,
    date
  };
};

export default useProgramDetails;
