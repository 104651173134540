import React from "react";
import Style from "./simpleReportText.module.scss";
import { Tooltip } from "react-tooltip";

export const SimpleReport = ({
  extraClassNames,
  data,
  showButton,
  buttonText,
  handleButtonClick,
}) => {

  return (
    <div className={`${Style.root} ${extraClassNames}`}>
      <div className={Style.textWrapper}>
        <div className={`${Style.textInner} col-12`}>
          <div className="row">
            {data?.map((item, index) => (
              <div
                className={`${Style.item} col`}
                key={index}
                data-tooltip-id={`tooltip-${index}`}
                data-tooltip-html={
                  item?.value
                    ? `<h6>${item?.label?.label} :</h6><p>${
                        item?.label?.tooltip || ""
                      }</p>`
                    : ``
                }
              >
                <p className="pro-fw-medium pro-mb-1">{item?.label?.label}</p>
                <h5 className="pro-ttl h5 pro-mb-0">{item?.value}</h5>
                <Tooltip
                  id={`tooltip-${index}`}
                  place="top"
                  effect="solid"
                  variant="white"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {showButton && (
        <div className={Style.actionWrapper}>
          <button
            onClick={handleButtonClick}
            className="pro-btn pro-btn-outline"
          >
            <span>{buttonText}</span>
          </button>
        </div>
      )}
    </div>
  );
};
